import request from '@/utils/request1';

// 追溯产品管理查询
export function fromProductList(data) {
    return request({ url: '/trace_product/QTraceProductView2', method: 'post', data })
}

// 追溯产品管理新增
export function fromProductAdd(data) {
    return request({ url: '/trace_product/ATraceProductView2', method: 'post', data })
}

// 追溯产品管理编辑
export function fromProductEdit(data) {
    return request({ url: '/trace_product/UTraceProductView2', method: 'post', data })
}

// 追溯产品管理删除
export function fromProductDel(data) {
    return request({ url: '/trace_product/DTraceProductView2', method: 'post', data })
}

// 二维码下载
export function generateQR(data) {
    return request({ url: '/trace_product/GenerateQRView2', method: 'post', data })
}

// 半成品及成品管理查询
export function traceabilityList(data) {
    return request({ url: '/traceability/QTraceabilityView2', method: 'post', data })
}

// 半成品及成品管理新增
export function traceabilityAdd(data) {
    return request({ url: '/traceability/ATraceabilityView2', method: 'post', data })
}

// 半成品及成品管理编辑
export function traceabilityEdit(data) {
    return request({ url: '/traceability/UTraceabilityView2', method: 'post', data })
}

// 半成品及成品管理删除
export function traceabilityDel(data) {
    return request({ url: '/traceability/DTraceabilityView2', method: 'post', data })
}

// 追溯批次查询
export function tracePici(data) {
    return request({ url: '/traceability/ProductBatchView2', method: 'post', data })
}

