<template>
  <div>
    <a-modal
      v-model="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      @cancel="cancel"
      @ok="confirm"
    >
      <div slot="title">{{ form.id ? "编辑" : "新增" }}</div>
      <div>
        <a-form-model
          ref="form"
          :model="form"
          :rules="rules"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-form-model-item prop="product_id" label="产品名称">
            <a-select
              v-model="form.product_id"
              style="width: 100%"
              @change="onChange"
            >
              <a-select-option
                v-for="(val, index) in productList"
                :key="index"
                :value="val.id"
              >
                {{ val.product_name }} ({{ val.product_id }})
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="batch" label="生产日期/批次">
            <a-date-picker v-model="form.batch" valueFormat="YYYYMMDD" />
          </a-form-model-item>
          <!-- <a-form-model-item prop="batch">
            <a-select v-model="form.batch" style="width: 100%">
              <a-select-option
                v-for="(val, index) in batchList.pici"
                :key="index"
                :value="val"
              >
                {{ val }}
              </a-select-option>
            </a-select>
          </a-form-model-item> -->
          <a-form-model-item prop="number" label="编号">
            <a-input v-model="form.number" />
          </a-form-model-item>
          <a-form-model-item prop="title" label="原材料描述">
            <a-select mode="multiple" v-model="form.others.describe_y">
              <a-select-option v-for="(val, index) in titleList" :key="index" :value="val.value">
                {{ val.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="title" label="生产投料描述">
            <a-select mode="multiple" v-model="form.others.describe_s">
              <a-select-option v-for="(val, index) in titleList1" :key="index" :value="val.value">
                {{ val.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <div>
            <a-button
              type="primary"
              @click="addNewForm"
              style="margin-bottom: 20px"
              >新增原料信息</a-button
            >
            <div v-for="(forms, index) in form.material" :key="index">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <span>状态 </span>
                <a-select v-model="forms.state" style="width: 85%">
                  <a-select-option value="正常"> 正常 </a-select-option>
                  <a-select-option value="异常"> 异常 </a-select-option>
                </a-select>
                <a-button
                  style="margin-left: 10px"
                  size="small"
                  @click="removeFormItem2(index)"
                >
                  <a-icon type="minus" />
                </a-button>
              </div>
              名称
              <a-input
                v-model="forms.product_name"
                disabled
                style="width: 85%; margin: 20px 0"
              /><br />
              重量
              <a-input
                v-model="forms.weight"
                style="width: 85%"
                @change="onRefresh"
              />
              kg<br />
              批次
              <a-input
                v-model="forms.material_batch"
                style="width: 85%; margin: 20px 0"
                @change="onRefresh"
              />
              <div
                style="
                  display: flex;
                  justify-content: left;
                  margin-bottom: 20px;
                "
              >
                <span style="display: block; width: 18%">检验报告 </span>
                <a-upload
                  accept=".png,.jpg,.jpeg"
                  :action="uploadUrl"
                  :maxCount="1"
                  list-type="picture-card"
                  :file-list="forms.report_img"
                  @preview="handlePreview"
                  @change="(fileList) => handleChange(fileList, index)"
                  name="file"
                >
                  <div
                    v-if="
                      (forms.report_img && forms.report_img.length < 1) ||
                      forms.report_img == null
                    "
                  >
                    <a-icon type="plus" />
                    <div class="ant-upload-text"></div>
                  </div>
                </a-upload>
              </div>
            </div>
            <div v-for="(form, index) in formList" :key="index">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <span>状态 </span>
                <a-select v-model="form.state" style="width: 85%">
                  <a-select-option value="正常"> 正常 </a-select-option>
                  <a-select-option value="异常"> 异常 </a-select-option>
                </a-select>
                <a-button
                  style="margin-left: 10px"
                  size="small"
                  @click="removeFormItem1(index)"
                  v-show="formList.length >= 1"
                >
                  <a-icon type="minus" />
                </a-button>
              </div>
              名称
              <a-input
                v-model="form.product_name"
                style="width: 85%; margin: 20px 0"
              /><br />
              重量 <a-input v-model="form.weight" style="width: 85%" /> kg<br />
              批次
              <a-input
                v-model="form.material_batch"
                style="width: 85%; margin: 20px 0"
              />
              <div
                style="
                  display: flex;
                  justify-content: left;
                  margin-bottom: 20px;
                "
              >
                <span style="display: block; width: 18%">检验报告 </span>
                <a-upload
                  accept=".png,.jpg,.jpeg"
                  :action="uploadUrl"
                  :maxCount="1"
                  list-type="picture-card"
                  :file-list="form.report_img"
                  @preview="handlePreview"
                  @change="(fileList) => handleChange1(fileList, index)"
                  name="file"
                >
                  <div
                    v-if="
                      (form.report_img && form.report_img.length < 1) ||
                      form.report_img == null
                    "
                  >
                    <a-icon type="plus" />
                    <div class="ant-upload-text"></div>
                  </div>
                </a-upload>
              </div>
            </div>
          </div>
          <a-form-model-item prop="start" label="混合起始时间">
            <a-date-picker
              v-model="form.start"
              show-time
              placeholder="混合起始时间"
            />
          </a-form-model-item>
          <a-form-model-item prop="end" label="混合结束时间">
            <a-date-picker
              v-model="form.end"
              show-time
              placeholder="混合结束时间"
            />
          </a-form-model-item>
          <a-form-model-item prop="situation" label="混合均匀情况">
            <a-input v-model="form.situation" />
          </a-form-model-item>
          <div>
            <a-button
              type="primary"
              @click="addNewForm1"
              style="margin-bottom: 20px"
              >新增抽样产品净含量</a-button
            >
            <div v-for="(form, index) in weightList" :key="index">
              抽样产品净含量{{ index + 1 }}
              <a-input
                v-model="form.weight"
                style="width: 68%; margin-bottom: 20px"
              />
              <a-button
                style="float: right"
                size="small"
                @click="removeFormItem(index)"
                v-show="weightList.length >= 1"
              >
                <a-icon type="minus" />
              </a-button>
            </div>
          </div>
          <a-form-model-item prop="recorder" label="记录人">
            <a-input v-model="form.recorder" allowClear />
          </a-form-model-item>
          <a-form-model-item prop="reviewer" label="复核人">
            <a-input v-model="form.reviewer" allowClear />
          </a-form-model-item>
          <a-form-model-item prop="remark" label="备注">
            <a-input type="textarea" v-model="form.remark" allowClear />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
    >
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { formulasAdd, formulasEdit } from "@/api/produce";
import { goodsInformationList } from "@/api/goods";
import { fromProductList, tracePici } from "@/api/from";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  name: "FormModal",
  props: ["visible", "form", "formList2", "clientsClassificationOptions"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      previewImage: "",
      titleList: [
        { label: '原料索证齐全', value: '原料索证齐全' },
        { label: '原料验收合格', value: '原料验收合格' }
      ],
      titleList1: [
        { label: '根据订单生产', value: '根据订单生产' }
      ],
      uploadUrl: process.env.VUE_APP_NEW_URL + "/comapi/UploadApi",
      productCount: 0,
      productCount1: 0,
      previewVisible: false,
      productList1: [],
      materialArr: [],
      formList: [],
      formList1: [],
      weightList: [],
      batchList: [],
      /**用中间数组来中转fileList */
      fileList: [],
      levelOptions: [
        { id: "0", name: "0" },
        { id: "1", name: "1" },
        { id: "2", name: "2" },
        { id: "3", name: "3" },
      ],
      productList: [],
      rules: {
        product_id: [
          { required: true, message: "请选择产品名称", trigger: "change" },
        ],
        batch: [{ required: true, message: "请选择批次", trigger: "change" }],
        number: [{ required: true, message: "请输入编号", trigger: "blur" }]
      },
      loading: false,
    };
  },
  watch: {
    "form.net_content": {
      handler(newVal) {
        if (Array.isArray(newVal)) {
          let arr = [];
          newVal.forEach((item) => {
            arr.push({
              weight: item,
            });
          });
          this.weightList = arr;
        }
      },
      deep: true,
    }
  },
  created() {
    this.formList = this.formList2
    this.productList1 = [];
    this.productCount1 = 0;
    const pageSize = 999999;
    const endIf = () => this.productList1.length >= this.productCount1;
    const loopLoad = (page, end) => {
      return goodsInformationList({ page, page_size: pageSize }).then((res) => {
        this.productCount1 = res.count || this.productCount1;
        this.productList1.push(...res.results);
        if (!end()) {
          return loopLoad(page + 1, end);
        }
      });
    };
    loopLoad(1, endIf);
    this.productList = [];
    this.productCount = 0;
    fromProductList({ page: 1, page_size: 999999 }).then((res) => {
      this.productCount = res.count || this.productCount;
      this.productList = res.data;
    });
     /**初始化fileList的数据 */
    if (this.form && this.form.material) {
      const material = this.form.material;
      if (Array.isArray(material)) {
        material.forEach((node, index) => {
          if (node.report_img) {
            const files = node.report_img.map((item) => {
              const n = item.split("/");
              return {
                uid: index + "",
                name: n[n.length - 1],
                status: "done",
                url: item,
              };
            });
            node.report_img = files;
          }
        });
      }
    }
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    onRefresh() {
      this.$forceUpdate();
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }, index) {
        this.$set(this.form.material[index], "report_img", fileList);
        this.$forceUpdate();

    },
    handleChange1({ fileList }, index) {
      this.$set(this.formList[index], "report_img", fileList);
      this.$forceUpdate();
    },
    removeFormItem(index) {
      this.weightList.splice(index, 1);
    },
    removeFormItem1(index) {
      this.formList.splice(index, 1);
    },
    removeFormItem2(index) {
      this.form.material.splice(index, 1);
      this.$forceUpdate();
    },
    addNewForm() {
      this.formList.push({
        state: "正常",
        product_name: "",
        weight: "",
        material_batch: "",
        report_img: [],
      }); // 在表单数组中新增一个空字段
    },
    addNewForm1() {
      this.weightList.push({ weight: "" });
    },
    onChange(val) {
      this.batchList = [];
      delete this.form.batch;
      this.productList.forEach((item) => {
        if (item.id == val) {
          this.materialArr = item.goods.association;
        }
      });
      let arr = [];
      this.materialArr &&
        this.materialArr.forEach((item) => {
          let info = "";
          this.productList1.forEach((node) => {
            if (node.id == item) {
              info = node.name;
            }
          });
          arr.push({
            state: "正常",
            product_name: info,
            weight: "",
            material_batch: "",
            report_img: [],
          });
        });
      this.form.material = arr;
      if(arr && arr.length === 0) {
        this.formList.push({
          state: "正常",
          product_name: "",
          weight: "",
          material_batch: "",
          report_img: [],
        });
      } else {
        this.formList = []
      }
      this.batchListInfo(val, 0);
    },
    async batchListInfo(id) {
      let res1 = await tracePici({ product_id: id });
      this.batchList = res1.data;
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.form.material = this.formList.concat(this.form.material);
          this.form.material.forEach((item) => {
            item.report_img =
              item.report_img &&
              item.report_img
                .filter((item) => item.status === "done")
                .map((item) => {
                  if (item.url) return item.url;
                  return item.response.data.file_url;
                });
          });
          let arr = [];
          this.weightList.forEach((item) => {
            arr.push(item.weight);
          });
          this.form.net_content = arr;
          this.form.start = this.form.start && moment(this.form.start).format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T');
          this.form.end = this.form.end && moment(this.form.end).format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T');
          let func = this.form.id ? formulasEdit : formulasAdd;
          func(this.form)
            .then((res) => {
              if (res.code !== 200) {
                this.$message.warn(res.message)
              } else {
                this.$message.success(this.form.id ? "修改成功" : "新增成功");
                this.$emit(this.form.id ? "update" : "create");
                this.cancel();
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.formList = [];
      this.weightList = [];
      if (!this.form.id) {
        this.$refs.form.resetFields();
      }
    },
  },
};
</script>

<style scoped></style>
